import React from "react"
import NewLayout from "../components/new_layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Tag from "../components/v2023/UI/Tag/tag"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReadyToTalk from "../components/v2023/ReadyToTalk/ready-to-talk"

const CustomerHubInPersonChannelCallCenter = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const page = data.page

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={lang}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
                image={page.seo?.image_2?.localFile?.publicURL}
            />

            <main className="main">
                <div className="main__section main__section--50 main__section--50--lg color-section purple-dark">
                    <div className="container">
                        <div className="container__50">
                            <div className="tag-group ai-flex-start">
                                {page.tags.map((tag, index) => <Tag key={index} tag={tag} />)}
                            </div>
                            <h1 className="merriweather">{page.title}</h1>
                            <div className="container__text">
                                <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>

                        <div className="container__50" data-aos="fade-left">
                            <GatsbyImage alt={page.image.alternativeText} image={getImage(page.image.localFile)} />
                        </div>
                    </div>
                </div>

                {page.info_items.map((item, index) => (
                    <div className={`main__section main__section--50 main__section--50--md ${index % 2 === 0 ? "main__section--50-invert" : ""}`} key={index}>
                        <div className="container">
                            <div className="container__50">
                                <h2>{item.title}</h2>
                                <div className="container__text">
                                    <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                </div>
                            </div>

                            <div className="container__50">
                                <GatsbyImage
                                    alt={item.image.alternativeText}
                                    image={getImage(item.image.localFile)} />
                            </div>
                        </div>
                    </div>
                ))}

                <div className="main__section main__section--100">
                    <div className="container container--center">
                        <div className="grid-lg-4">
                            {page.other_functionalities.map((item, index) => {
                                const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                                return <div className="image-group" key={index} {...animation}>
                                    <div>
                                        <GatsbyImage alt={item.image.alternativeText} image={getImage(item.image.localFile)} />
                                    </div>
                                    <div className="image-group__text">
                                        <h5>{item.title}</h5>
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>

                <ReadyToTalk lang={lang} />
            </main>
        </NewLayout>
    )
}

export default CustomerHubInPersonChannelCallCenter

export const customerHubInPersonChannelCallCenterQuery = graphql`
    query($langKey: String) {
        page: strapi2023ChChannelInpersonCallcenter ( locale: { eq: $langKey } ){
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL
                    }
                }
            }
            tags {
                name
                type
            }
            title
            description
            image {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 608
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            info_items {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 608
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            other_functionalities {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 608
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
        }
    }
`
